import classNames from 'clsx';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  logBoostFeatureAction,
  logBoostOptInAction,
  logBoostOptOutAction,
} from 'src/common/actions/logging';
import { playBoostStation, setBoostTooltip } from 'src/common/actions/player';
import Popover from 'src/common/components/shared/Popover';
import InkButton from 'src/common/components/shared/button/InkButton';
import NakedButton from 'src/common/components/shared/button/NakedButton';
import boost from 'src/common/constants/analytics/categoryActionLabel/boost';
import { boostTooltipDisplayCount } from 'src/common/constants/cookies';
import {
  BOOST_INTRO_TOOLTIP,
  BOOST_TOOLTIP_OPT_IN_BTN,
  BOOST_TOOLTIP_OPT_OUT_BTN,
} from 'src/common/constants/localizations/boost-aka-switch';
import { playerStatuses } from 'src/common/constants/playerStatuses';
import useActions from 'src/common/hooks/useActions';
import { useOnClickOutside } from 'src/common/hooks/useOnClickOutside';
import usePrevious from 'src/common/hooks/usePrevious';
import { useWindowSize } from 'src/common/hooks/useWindowSize';
import { LocationAndLocalizationContext } from 'src/common/providers/LocationAndLocalizationProvider';
import { selectBoostTooltipEnabled } from 'src/common/selectors/config';
import {
  selectIsBoostFeaturedInPlayer,
  selectIsBoostStation,
  selectIsBoostTooltipOpen,
  selectNowPlaying,
  selectNowPlayingBoost,
  selectPlayerStatus,
} from 'src/common/selectors/player';
import assetUrl from 'src/common/utils/assetUrl';
import { getBoostNextPlayButtonWidth } from 'src/common/utils/boost';
import { isMedium } from 'src/common/utils/breakpoints';
import { getCookie, setCookie } from 'src/common/utils/cookie';
import css from './boost-intro-popover.module.scss';

function BoostIntroPopover({ breakpoint, isNowPlaying, nextPlayButtonRef }) {
  const [playingHasOccurred, setPlayingHasOccurred] = useState(false);
  const [popoverOffset, setPopoverOffset] = useState({});
  const isPopoverEnabled = useSelector(selectBoostTooltipEnabled);
  const boostMetadata = useSelector(selectNowPlayingBoost);
  const showBoostPopover = useSelector(selectIsBoostTooltipOpen);
  const nowPlaying = useSelector(selectNowPlaying);
  const playerStatus = useSelector(selectPlayerStatus);
  const isBoostStation = useSelector(selectIsBoostStation);
  const isBoostFeaturedInPlayer = useSelector(selectIsBoostFeaturedInPlayer);
  const ref = useRef();
  const actions = useActions({
    playBoostStation,
    setBoostTooltip,
    logBoostFeatureAction,
    logBoostOptInAction,
    logBoostOptOutAction,
  });
  const [width] = useWindowSize();
  const isMediumBreakpoint = isMedium(breakpoint);
  const boostNextPlayButtonWidth =
    getBoostNextPlayButtonWidth(isMediumBreakpoint);
  const tooltipDisplayCount = getCookie(boostTooltipDisplayCount.name);
  const prevNowPlaying = usePrevious(nowPlaying);

  const handleClose = (loggingAction) => {
    const boostLoggingAction = loggingAction || actions.logBoostOptOutAction;

    actions.setBoostTooltip(false);
    boostLoggingAction(boost.labels.tooltip);
    setCookie(boostTooltipDisplayCount.name, null, 1);
  };

  const generateTooltipOffset = useCallback(() => {
    const { left: nextButtonLeftOffset } =
      nextPlayButtonRef?.current?.getBoundingClientRect() || {};
    const arrowLeftOffset = 203; // popover arrow left offset
    const arrowWidth = 12; // $arrowBorderSize in css
    const leftOffset =
      nextButtonLeftOffset -
      (boostNextPlayButtonWidth / 2 -
        (boostNextPlayButtonWidth - (arrowLeftOffset + arrowWidth)));

    return {
      left: `${leftOffset}px`,
      right: 0,
    };
  }, [boostNextPlayButtonWidth, nextPlayButtonRef]);

  useOnClickOutside(ref, () => {
    if (showBoostPopover) {
      handleClose();
    }
  });

  useEffect(() => {
    const { left, right } = generateTooltipOffset() || {};

    if (isMediumBreakpoint) {
      return setPopoverOffset({ left });
    }

    return setPopoverOffset({ right });
  }, [generateTooltipOffset, showBoostPopover, width, isMediumBreakpoint]);

  // NOTE: The purpose of playingHasOccurred is we do not want to show the ToolTip while Preroll is connecting,
  // The reason for this is once the app attempts preroll, we cannot stop it until after it has started...
  // and if the user attempts to 'switch' during the time preroll is loading, the user will still hear preroll before they hear their switch station
  // But, the ask from the business is to NOT run preroll on a switch station....
  // So, since preroll runs through the IMA player, which is seperate from Web Tuner,
  // we need to know that playerStatus has reached a playback state (either mediaAd or stream has started playback),
  // before we can safely show the Tooltip
  // at which point it is safe to stop our ads player or our Tuner, and switch the stream
  useEffect(() => {
    if (!playingHasOccurred) {
      setPlayingHasOccurred(playerStatus === playerStatuses.playing);
    }
  }, [playerStatus, playingHasOccurred]);

  useEffect(() => {
    if (nowPlaying !== prevNowPlaying) {
      // NOTE: Reset this state whenever the station changes & return so to wait for playerStatus to be 'playing' again
      return setPlayingHasOccurred(false);
    }

    // NOTE: Show popover if the following conditions are met:
    // 1. Popover is enabled via RM config
    // 2. Popover is currently closed
    // 3. If tooltip has not been shown - Future TODO: Popover Display cap has not been breached
    // 4. boostMetadata (via NP) is hydrated (confirms there is metaData that we can use to display the switch statio in the UI)
    // 5. boost has not been initialized (isBoostStation is not set until after Tune.ashx response is processed,
    //    but isBoostFeaturedInPlayer is set immediately after the user's boost click occurs, which is when we should close the tooltip)
    // 6. The current station is not a Boost station (isBoostStation)
    // 7. We do not want to begin switch while in an initial loading state,
    //    so we wait for some type of playback (preroll or stream) to occur

    const shouldShowBoostPopover =
      isPopoverEnabled &&
      !showBoostPopover &&
      !tooltipDisplayCount &&
      !isEmpty(boostMetadata) &&
      !isBoostFeaturedInPlayer &&
      !isBoostStation &&
      playingHasOccurred;

    if (shouldShowBoostPopover) {
      actions.logBoostFeatureAction(boost.actions.show, boost.labels.tooltip);
      actions.setBoostTooltip(true);
    }
  }, [
    actions,
    boostMetadata,
    isBoostFeaturedInPlayer,
    isBoostStation,
    isPopoverEnabled,
    nowPlaying,
    playingHasOccurred,
    prevNowPlaying,
    showBoostPopover,
    tooltipDisplayCount,
  ]);

  const { getLocalizedText } = useContext(LocationAndLocalizationContext);

  const handleTrySwitch = () => {
    handleClose(actions.logBoostOptInAction);
    actions.playBoostStation();
  };

  return (
    <Popover
      id="boostIntroPopover"
      isOpen={showBoostPopover}
      className={classNames(
        css.popoverContainer,
        isNowPlaying ? css.nowPlayingPositioning : css.playerPositioning,
      )}
      contentClassName={css.popoverContent}
      styleOverride={popoverOffset}
      handleCloseClick={() => handleClose()}
      popoverRef={ref}
      showCornerClose
    >
      <img
        className={css.switchLogo}
        src={assetUrl('assets/img/shared/switch-logo.svg')}
      />
      <div className={css.tooltipText}>
        {getLocalizedText(BOOST_INTRO_TOOLTIP)}
      </div>
      <InkButton
        id="boostOptIn"
        className={css.dialogCtaButton}
        label={getLocalizedText(BOOST_TOOLTIP_OPT_IN_BTN)}
        onClick={handleTrySwitch}
      />
      <NakedButton
        id="boostOptOut"
        className={css.dialogCtaButton}
        label={getLocalizedText(BOOST_TOOLTIP_OPT_OUT_BTN)}
        onClick={() => handleClose()}
      />
    </Popover>
  );
}

BoostIntroPopover.propTypes = {
  nextPlayButtonRef: PropTypes.object.isRequired,
  breakpoint: PropTypes.number.isRequired,
  isNowPlaying: PropTypes.bool,
};

export default BoostIntroPopover;
