import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { tunerSingleton } from '../../../client/tuner';
import {
  MIDROLL_BREAKS_PER_SESSION,
  MIDROLL_ENABLED,
  MIDROLL_INTERVAL_SECONDS,
} from '../../constants/experiments/ads';
import useExperiment from '../../hooks/useExperiment';
import useOnMount from '../../hooks/useOnMount';
import {
  selectIsBoostFeaturedInPlayer,
  selectIsOutroInProgress,
  selectNowPlayingCanShowMediaAds,
  selectTunedGuideId,
} from '../../selectors/player';
import midrollScheduler from '../../utils/ads/MidrollScheduler';

function onPaused() {
  if (midrollScheduler.isActive && !midrollScheduler.isPlayingMidroll) {
    midrollScheduler.pause();
  }
}

function onEnded() {
  if (midrollScheduler.isActive) {
    midrollScheduler.reset();
  }
}

// TODO: convert to useMidroll hook once WithPlayer.js is converted to functional component
export default function MidrollHook() {
  const isMidrollEnabled = useExperiment(MIDROLL_ENABLED);
  const midrollBreaksPerSession = useExperiment(MIDROLL_BREAKS_PER_SESSION);
  const midrollIntervalSeconds = useExperiment(MIDROLL_INTERVAL_SECONDS);
  const canPlayMediaAds = useSelector(selectNowPlayingCanShowMediaAds);
  const loadedGuideId = useSelector(selectTunedGuideId);
  const isBoostFeatured = useSelector(selectIsBoostFeaturedInPlayer);
  const isOutroInProgress = useSelector(selectIsOutroInProgress);
  const isBoostInProgress = isBoostFeatured || isOutroInProgress;

  const onPlaying = useCallback(
    (stream) => {
      if (!stream?.isMidrollEnabled || !canPlayMediaAds || isBoostInProgress) {
        return;
      }

      if (midrollScheduler.isPaused) {
        midrollScheduler.resume();
        return;
      }

      if (midrollScheduler.isNewGuideId(loadedGuideId)) {
        midrollScheduler.reset();
      }

      midrollScheduler.scheduleNextMidroll(loadedGuideId);
    },
    [canPlayMediaAds, loadedGuideId, isBoostInProgress],
  );

  useOnMount(() => {
    if (!isMidrollEnabled) {
      return;
    }

    const { paused, stopped } = tunerSingleton.instance?.events || {};

    tunerSingleton.instance?.on(paused, onPaused);
    tunerSingleton.instance?.on(stopped, onEnded);
    midrollScheduler.init({
      midrollBreaksPerSession,
      midrollIntervalSeconds,
    });

    return () => {
      tunerSingleton.instance?.off(paused, onPaused);
      tunerSingleton.instance?.off(stopped, onEnded);
      midrollScheduler.reset();
    };
  });

  useEffect(() => {
    if (!isMidrollEnabled) {
      return;
    }

    const { playing } = tunerSingleton.instance?.events || {};

    tunerSingleton.instance?.on(playing, onPlaying);

    return () => {
      tunerSingleton.instance?.off(playing, onPlaying);
    };
  }, [isMidrollEnabled, onPlaying]);

  useEffect(() => {
    if (!(isMidrollEnabled && midrollScheduler.isActive) || isBoostInProgress) {
      return;
    }

    const { playing, paused, stopped } = tunerSingleton.instance?.events || {};

    midrollScheduler.resume();
    tunerSingleton.instance?.on(playing, onPlaying);
    tunerSingleton.instance?.on(paused, onPaused);
    tunerSingleton.instance?.on(stopped, onEnded);

    return () => {
      midrollScheduler.pause();
      tunerSingleton.instance?.off(playing, onPlaying);
      tunerSingleton.instance?.off(paused, onPaused);
      tunerSingleton.instance?.off(stopped, onEnded);
    };
  }, [isMidrollEnabled, isBoostInProgress, onPlaying]);

  return null;
}
