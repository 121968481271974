import PropTypes from 'prop-types';

const Checkmark = ({
  className,
  style,
  viewBox,
  height,
  width,
  fill,
  dataTestId,
}) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    style={style}
    className={className}
    data-testid={dataTestId}
    fill="none"
  >
    <path
      d="M19 13L13.3991 19L11 16.43"
      stroke={fill}
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
  </svg>
);

Checkmark.propTypes = {
  dataTestId: PropTypes.string,
  className: PropTypes.string,
  viewBox: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  fill: PropTypes.string.isRequired,
};

export default Checkmark;
