import classNames from 'clsx';
import PropTypes from 'prop-types';
import PillButton from './PillButton';
import css from './pill-button.module.scss';

const NakedButton = ({ className, ...otherProps }) => (
  <PillButton className={classNames(className, css.naked)} {...otherProps} />
);

NakedButton.propTypes = {
  className: PropTypes.string,
};

export default NakedButton;
